@media only screen and (min-width: 1441px) {
  .tp-page-width {
    width: 90%;
    margin: 0 auto;
  }

  .tp-container {
    width: 1170px;
    margin: 0 auto;
  }

  .tp-quote-img img,
  .tp-banner img,
  .tp-about img,
  .tp-price img,
  .tp-blogs img {
    width: 100% !important;
  }

  .tp-top-feat-box {
    padding: 40px 32px;
  }
}

@media only screen and (max-width: 1440px) {
  .tp-page-width {
    width: 90%;
    margin: 0 auto;
  }

  /* .tp-menu-block {
    display: none;
  } */

  .tp-shift.tp-menu-block {
    display: block;
    border-top: 1px solid lightgrey;
  }

  .tp-nav-menu li {
    margin: 0 40px 0 0;
  }

  .tp-header-btn {
    margin-right: -70px;
  }

  .tp-ban-content {
    width: 45%;
  }

  .tp-hide {
    display: none;
  }

  .tp-top-feat-con {
    width: 65%;
  }

  .tp-about {
    /* padding: 180px 0 80px; */
    background-color: #f8f8f8;
  }

  .tp-about-box,
  .tp-blog-wrap,
  .tp-price-wrap {
    position: relative;
    left: auto;
    top: auto;
    transform: translate(0, 0);
  }

  .tp-price {
    background-color: #f8f8f8;
    padding: 80px 0;
  }

  .tp-blogs {
    position: relative;
    background-color: #f8f8f8;
    padding: 80px 0;
  }
}

@media only screen and (max-width: 1200px) {
  .tp-container {
    width: 992px;
  }

  .tp-test-slide {
    width: 37% !important;
  }

  .tp-testimonal .swiper-slide {
    margin-left: 30px;
  }

  .tp-sales-img::after {
    left: 46px;
    width: 52%;
  }
  .tp-header-btn {
    margin-right: -60px;
  }

  .tp-ban-con {
    padding: 30px 60px;
  }

  .tp-ban-title h2 {
    font-size: 42px;
  }

  .tp-about-left {
    width: 50%;
  }

  .tp-about-right {
    width: 47%;
  }

  .tp-about-title.tp-title-con h2 {
    width: 100%;
  }

  .tp-about-exp {
    top: 65%;
    width: 50%;
  }

  .tp-about-con a {
    padding: 14px 30px;
  }

  .tp-top-feat-con {
    width: 70%;
  }

  .tp-sales-box {
    width: 20%;
    padding: 50px 15px;
  }

  .tp-feature-card {
    width: 26%;
  }

  .tp-foot-menu.tp-foot-twit {
    width: 29%;
  }

  .tp-foot-menu {
    width: 20%;
  }
}

@media only screen and (max-width: 1024px) {
  .tp-container {
    width: 768px;
  }

  .tp-sales-img::after {
    left: 25px;
    width: 65%;
  }

  .tp-quote-btn-tittle a {
    padding: 25px 20px 24px;
  }

  .tp-quote-btn-img a {
    padding: 18px 20px 18px;
    background-color: #ad88bc;
    display: inline-block;
  }

  .tp-header-btn {
    margin-right: -50px;
  }

  .tp-contact-btn {
    margin-right: 40px;
  }

  .tp-ban-title h2 {
    font-size: 36px;
  }

  .tp-ban-content {
    width: 50%;
  }

  .tp-ban-con {
    padding: 30px 40px;
  }

  .tp-top-feat-con {
    width: 83%;
  }

  .tp-about-exp span {
    padding: 14px 15px;
    font-size: 28px;
  }

  .tp-about-exp {
    top: 45%;
    width: 55%;
  }

  .tp-about-con p {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 15px;
  }

  .tp-sales-title h3 {
    font-size: 15px;
  }

  /* .tp-feat-con a {
        width: 44%;
    } */

  .tp-price-wall {
    display: block;
  }

  .tp-price-left {
    width: 98%;
    padding: 40px 7px 6px;
  }

  .tp-price-right-box input {
    width: 88%;
  }

  .tp-price-right {
    margin-top: 40px;
  }

  .tp-blog-con {
    padding: 30px 10px;
  }

  .tp-blog-con a h3 {
    font-size: 18px;
  }

  .tp-quote-content h5 {
    font-size: 15px;
    margin-bottom: 18px;
  }

  .tp-quote-con {
    justify-content: space-between;
  }

  .tp-quote-content h3 {
    font-size: 28px;
    width: 100%;
  }

  .tp-quote-wrap a {
    padding: 15px 30px;
  }

  .tp-footer-top {
    flex-wrap: wrap;
  }

  .tp-foot-content {
    width: 38%;
  }

  .tp-foot-menu {
    width: 40%;
    margin-bottom: 60px;
  }

  .tp-foot-menu.tp-foot-twit {
    width: 40%;
  }

  .tp-footer {
    padding: 80px 0px 40px;
  }

  .tp-foot-menu h3::after {
    width: 25%;
  }

  .tp-test-slide {
    width: 35% !important;
    padding: 40px 27px;
  }
}

@media only screen and (max-width: 768px) {
  .tp-top-feat {
    margin-bottom: 0px !important;
  }
  .tp-container {
    width: 525px;
  }

  .tp-sales-img::after {
    left: 52px;
    width: 49%;
  }

  .tp-toggle {
    margin-right: 40px;
  }

  .tp-ban-title h2 {
    font-size: 24px;
  }

  .tp-ban-title h5 {
    font-size: 12px;
  }

  .tp-ban-con {
    padding: 30px 20px;
  }

  .tp-shift.tp-menu-block {
    display: none;
  }

  .tp-ban-btn a {
    font-size: 12px;
    padding: 12px 15px;
  }

  .tp-ban-track a {
    font-size: 12px;
    padding: 12px 15px;
  }

  .tp-ban-content {
    width: 50%;
  }

  .tp-ban-btn-box {
    display: block;
  }

  .tp-ban-track {
    margin-top: 20px;
    width: 85%;
  }

  .tp-ban-content {
    left: 35%;
  }

  .tp-ban-con {
    width: 85%;
  }

  .tp-test-slide {
    width: 83% !important;
  }

  .tp-con-btn-tittle {
    display: none;
  }

  .tp-quote-btn-tittle {
    display: none;
  }

  .tp-header-btn {
    margin-right: 0;
  }

  .tp-contact-btn {
    margin: 8px 40px 8px 0;
  }

  .tp-quote-btn-img a {
    padding: 10px 15px 9px;
  }

  .tp-contact-btn {
    margin: 8px 0px 8px 0;
  }

  .tp-quote-btn {
    box-shadow: -10px 7px 18px -6px #ef5711;
  }

  .tp-top-feat {
    margin-top: 0;
    padding: 40px 0;
  }

  .tp-top-feat-con {
    width: 98%;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
  }

  .tp-top-feat-box {
    padding: 40px 20px;
  }

  .tp-about {
    /* padding: 90px 0 80px; */
    margin-top: 0;
  }

  .tp-about-wrap {
    display: block;
  }

  .tp-about-left {
    width: 100%;
  }

  .tp-about-right {
    width: 100%;
  }

  .tp-about-exp {
    top: 78%;
    width: 45%;
  }

  .tp-about-con p {
    font-size: 15px;
  }

  .tp-sales-content {
    flex-wrap: wrap;
  }

  .tp-sales-box {
    width: 40%;
    margin: 25px 0;
  }

  .tp-feature-card {
    width: 40%;
    padding: 40px 15px 50px;
    margin: 0 10px 70px;
  }

  .tp-blog-bottom {
    display: block;
  }

  .tp-blog-btm-box {
    text-align: center;
  }

  .tp-blog-info {
    left: 70px;
  }

  .tp-blogs {
    padding: 80px 0 50px;
  }

  .tp-blog-con {
    padding: 30px 10px;
    width: 71%;
    margin: 0 auto;
    text-align: left;
  }

  .tp-blog-content {
    margin: 0 15px 40px;
  }

  .tp-quote-content h3 {
    font-size: 18px;
  }

  .tp-quote-wrap a {
    padding: 10px 18px;
  }

  .tp-footer-top {
    display: block;
  }

  .tp-foot-content {
    width: 100%;
    margin-bottom: 60px;
  }

  .tp-foot-menu {
    width: 100%;
    margin-bottom: 60px;
  }

  .tp-foot-menu.tp-foot-twit {
    width: 100%;
    margin-bottom: 10px;
  }

  .tp-toggle {
    display: block;
    /* position: relative; */
  }

  .tp-toggle-menu {
    position: fixed;
    top: 65px;
    left: 3px;
    background-color: #ffff;
    z-index: 10;
    width: 98%;
    padding: 20px 5px 20px 10px;
    display: none;
  }

  .tp-toggle-menu li a {
    padding: 10px;
    color: #000;
    text-decoration: none;
  }

  .tp-toggle-menu li {
    margin-bottom: 15px;
  }

  .tp-toggle a.tp-meanmenu-reveal {
    color: #444;
    cursor: pointer;
    display: block;
    /* padding: 8px 9px; */
    text-decoration: none;
    width: 22px;
  }

  .tp-toggle a.tp-meanmenu-reveal span {
    background: #ff4800;
  }

  .tp-toggle a.tp-meanmenu-reveal span {
    background: #444 none repeat scroll 0 0;
    display: block;
    height: 3px;
    margin-top: 3px;
  }

  .tp-testimonal .swiper-slide {
    margin-left: 30px;
  }

  .tp-test-slide {
    width: 82% !important;
  }

  .tp-top-feat-box:first-child {
    border-left: 0;
  }

  .tp-top-feat-box:last-child {
    border-right: 0;
  }

  .tp-top-feat-con {
    border-left: 3px solid var(--tp-main-color);
    border-right: 3px solid var(--tp-main-color);
  }
}

.tp-contact-btn {
  display: flex;
  align-items: center;
}

.tp-con-btn-img img {
  width: 40px; /* Adjust icon size */
  margin-right: 10px;
}

.tp-con-btn-title {
  display: flex;
  flex-direction: column;
}

.tp-con-btn-title span {
  font-size: 14px;
  color: #6d6d6d; /* Grey for the 'CONTACT US' text */
}

.tp-con-btn-title a {
  font-size: 18px;
  font-weight: bold;
  color: #5e2aa3; /* Adjust as needed for the brand */
  text-decoration: none;
}

.tp-con-btn-title a:hover {
  text-decoration: underline;
}


@media only screen and (max-width: 525px) {
  .tp-container {
    width: 90%;
  }

  .tp-sales-img::after {
    left: 42px;
    width: 54%;
  }

  .tp-toggle {
    margin-right: 0px;
  }

  .tp-logo-img {
    width: 70%;
  }

  .tp-toggle-menu {
    top: 55px;
  }

  .tp-header-btn {
    display: none;
  }

  .tp-ban-content {
    margin-top: 20px;
  }

  .tp-nav-bar {
    padding: 12px 0;
  }

  .tp-ban-content {
    left: 25%;
  }

  .tp-top-feat-con {
    flex-wrap: wrap;
  }

  .tp-top-feat-box {
    width: 30%;
    border: 1px solid #f4f4f4;
  }

  .tp-feature-card {
    width: 80%;
  }

  .tp-title-con h2 {
    font-size: 24px;
  }

  .tp-price-right-box input {
    width: 78%;
  }

  .tp-price-left-content tr:nth-child(odd) td {
    padding: 15px 10px;
  }

  .tp-price-left-content th {
    font-size: 12px;
  }

  .tp-price-left-content td {
    font-size: 12px;
    padding: 15px 10px;
  }

  .tp-price-left {
    width: 98%;
    padding: 40px 6px 6px;
  }

  .tp-test-slide {
    width: 80% !important;
  }

  .tp-blog-con {
    width: 80%;
  }

  .tp-quote-wrap {
    width: 100%;
  }

  .tp-quote-content h5 {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .tp-quote-content h3 {
    font-size: 14px;
  }

  .tp-quote-wrap a {
    font-size: 12px;
  }

  .tp-price-left-content {
    overflow: overlay;
  }

  .tp-price-left {
    width: 97%;
  }

  .tp-blog-con {
    width: 95%;
  }

  .tp-quote-content h5 {
    display: none;
  }

  .tp-quote-wrap a {
    font-size: 10px;
    width: 25%;
    padding: 3px 10px;
    letter-spacing: 0px;
    text-align: center;
  }

  .tp-quote-content h3 {
    font-size: 12px;
  }

  .tp-foot-wrap h5 {
    font-size: 12px;
  }

  .tp-ban-track {
    width: 68%;
    text-align: center;
  }

  .tp-ban-content {
    width: 98%;
    left: auto;
    top: auto;
    transform: translate(0, 0);
    position: relative;
    background-color: #f8f8f8;
  }
}

@media only screen and (max-width: 425px) {
  .tp-sales-content {
    justify-content: center;
  }

  .tp-sales-img::after {
    left: 119px;
    height: 122%;
    width: 30%;
    top: -14px;
  }

  .tp-test-slide {
    width: 75% !important;
    padding: 40px 25px;
  }

  .tp-about-exp {
    top: 72%;
    width: 70%;
    left: 35%;
  }

  .tp-sales-box {
    width: 90%;
  }

  .tp-blog-info {
    left: 10px;
  }

  .tp-top-feat-con {
    border-top: none;
    border-bottom: none;
  }

  .tp-top-feat-box {
    width: 65%;
    margin: 20px 0;
  }

  .tp-test-con-box {
    display: block;
    text-align: center;
  }

  .tp-test-con {
    margin-left: 0;
    text-align: center;
    margin-top: 10px;
  }

  .tp-test-con h5 {
    text-align: center;
  }
}
@media only screen and (max-width: 375px) {
  .tp-test-slide {
    width: 72% !important;
  }
  .tp-testimonal .swiper-slide {
    margin-left: 25px;
  }
  .tp-sales-img::after {
    left: 98px;
    height: 122%;
    width: 34%;
    top: -13px;
  }
}
@media only screen and (max-width: 320px) {
  .tp-test-slide {
    width: 77% !important;
    padding: 40px 20px;
  }
  .tp-testimonal .swiper-slide {
    margin-left: 14px;
  }
  .tp-ban-track {
    width: 78%;
  }
  .tp-sales-img::after {
    left: 76px;
    width: 40%;
  }
}
@media (max-width: 1399px) {
  .tb-before-cls {
    top: -27px;
    right: 19px;
  }
}
@media (max-width: 1024px) {
  .tb-before-cls {
    top: -15px;
    right: 0px;
  }
}
@media (max-width: 991px) {
  .tb-before-cls {
    top: -15px;
    right: 160px;
  }
  .tb-counter-box {
    margin-bottom: 23px;
  }
  .tp-about-con {
    margin-bottom: 40px;
  }
}
@media (max-width: 425px) {
  .tb-before-cls {
    right: 0px;
  }
  .tp-services-inner-text {
    top: 61px;
    left: 13px;
  }
}
@media (max-width: 767px) {
  .tp-services-img img {
    width: 100%;
  }
}


/* new design */
/* Responsive CSS
 * Author : MAQK
 * Author URI : www.aivahthemes.com
======================================================================== */
/* Mobile Menu & Custom CSS
======================================================================== */

@media only screen and (max-width : 959px ) {

	.widget-left-s2,
	.widget-right-s2 { display: none; }

	.widget-left-s3-mobile { display: table; width: 100%; }
	.widget-left-s3-mobile .icon_box_widget { padding-top: 0; padding-top: 20px; margin: 0;  }

	.front-boxes .vc_column_container { margin-top: 0px !important; margin-bottom: 0px !important; }
	.entry-content-wrapper .bcallout { margin-top: 0 !important; }

	/* MiniCart Hidden */
	.minicart-wrap{ display: none!important; }
	.partial_section_wrap { height: auto; }

	.ivaSearch { display: none; }
	.schedule-tabs li { border:0 !important; width: 50%; }

	.verticle-middle {
	    -webkit-transform: translateY(0);
	    -ms-transform: translateY(0);
	    transform: translateY(0);
	}

	.header-style4 { position: relative; margin-top: 0;}
	.header-style4 #social-icons { display: none!important; }
	.header-style4 .menuwrap { float: right; }
	.header-style4 .header { border-radius: 0; }
	.header-style4 { padding-top: 0; }

	.header-style5 { position: relative; }
	.header-style5 .header { background-color: #ffffff; }
	.header-style5 .header-area { display: table; }
	.header-style5 .logo { display: table-cell; }
	.header-style5 .iva-mobile-dropdown {
		margin: 20px 20px 0 auto;
	 }

	.md-modal {
    	max-width: 400px;
    	min-width: 75%;
	}
	.iva_instagram .one_fifth {
		  width: 50%;
		  margin: 0 !important;
	}
	.milestone_icon { font-size: inherit; }
}

 @media only screen and (max-width : 970px ) {
	.primarymenu .sf-menu { display: none !important; } 
	.iva-mobile-dropdown {
		display: block !important; position: absolute !important; top: 50%; right: 15px; margin-top: -10px !important;
	 }

 }

@media only screen and (max-width: 1440px) {
	.header-style4 + #featured_slider .flex-caption-inner { top: 60%!important; }
}


/* Regular Desktop Resolution
======================================================================== */

@media only screen and (min-width : 1025px) and (max-width : 1169px) {

	img { width:auto; max-width:100%; height:auto;}
	figure img { width: auto; max-width: 100%; height: auto; }

	.inner,
	.pagemid > .inner,
	.section_inner,
	.videoslider,
	.header .header-area,
	.subheader-inner,
	.iva_bk_inner {  width: 1000px !important; }

	#boxed #wrapper {
		width: auto !important;
		margin: 0 auto;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}

/* Small Desktop Resolution and iPad Landscape
======================================================================== */
@media only screen and (min-width : 960px) and (max-width : 1024px) {

	/* .primarymenu .sf-menu,
	.header-style4 .sf-menu{
    	display: none !important;
	} */
	.widget-left-s2, .widget-right-s2, .topbar {
		display: none;
	}

	.header-style4 .primarymenu { padding-right: 0; }
	.header-style2 #iva-mobile-nav-icon { margin: 50px; float: right;}
	#fixedheader .iva-mobile-menu {
	    position: absolute;
	    top: 100%;
		width: 100%;
		height: auto;
	}
	#fixedheader { height: 100px!important;}
	.subheader-inner { padding: 0 15px;	}

	.header-style4 {position: relative; margin: 0; padding-top: 0; }
	.header-style4 .header{ border-radius: 0; box-shadow: none; }
	.header-style4 .iva-mobile-dropdown{ margin: 10px 0; }
	.header-style4 .header-area { width: 100% !important; }
	.header-style4 .iva-mobile-menu{
		position: absolute;
	  	top: 100px;
	  	background-color: #252525;
	  	padding: 0;
	  	z-index: 99999;
	  	width: 100%;
	  	left: 0;
	}

	.icn_wrap { display: none; }
	.iva_mmenu {  width: 90% !important; }
	img { max-width:100%; height:auto;}
	figure img { max-width: 100%; height: auto; }


	#boxed #wrapper { width: auto !important; margin:0 auto; -webkit-box-shadow: none; box-shadow: none; }
	.header-area { width: 90%; }
	.inner { max-width: 900px; }
	.pagemid > .inner,
	.section_inner,
	.videoslider,
	.page_content,
	.menu-inner,
	.subheader-inner {  width: 100% !important;  }
	.vertical_leftmenu  .iva-mobile-dropdown {
    display: block;
    position: absolute !important;
    top: 50%;
    right: 15px;
    margin-top: -10px !important;
    }
	.serv-block {
		padding: 50px;
	}
	.footer-area-middle {
		padding: 60px 0 40px 40px;
	}
	.header .menu-dropdown {  display: block; right: 0px !important; float: right; }
	.header-style4 .search-box { display:none !important; }

	.callOutBox .callout_text { float: none;}
	.callout_text .fancyheading { text-align: center; }
	.callOutBox .callout_btn { float: none; margin: 0 auto; display: table; }
	.iva_instagram .one_fifth {
		  width: 50%;
		  margin: 0 !important;
	}
	.rightsidebar .content-area { padding: 0 15px; }
	.rightsidebar #sidebar .content { padding: 80px 20px 80px 25px; }
	.copyright_left,
	.copyright_right,
	.copyright_right .textright { float: none; width: 100%; text-align: center; }

	/* Verticle Menu */
	.vertical_leftmenu.boxed #iva-mobile-nav-icon{ margin:0 0 0 auto; }
	.sidemenu {
		position: relative;
		width: 100%;
		box-shadow: none;
	}
	.iva_vertical_menu{
		display: none;
	}


	.vertical_footerbar {
		display: none;
	}
	body.boxed.vertical_leftmenu{ padding: 0;}

	.iva-children-indenter { display: block !important; }
	.sidemenu .logo { padding: 20px 60px 20px 20px; text-align: left; margin: 0; }
	.sidemenu .menuwrap {
	    position: absolute;
	    top: 50%;
	    right: 15px;
	    margin-top: -10px;
	}
	.content-block {
		padding: 10px 60px !important;
	}
	.logo { padding: 0 15px; }
	.section_row  { padding: 80px 15px; }
	main.content-area { padding: 0 15px; }
}
/* iPad Portrait
======================================================================== */

@media only screen and (min-width: 768px) and (max-width: 959px) {
	#iva-mobile-nav-icon{ margin:0 0 0 auto; }

	img {max-width:100%; height:auto;}
	figure img { max-width: 100%; height: auto; }
	.widget-left-s3 { display: none; }
	.widget-left-s3-mobile { background-color: #353535; color: #ffffff; }
	.widget-left-s3-mobile .widget-left-s3 { width: 90%; float: none; display: table;  }
	.widget-left-s3-mobile .icon-box_widget { vertical-align: middle; display: table-cell;  }

	.callOutBox .callout_btn { float: left !important; margin-left: 10px; margin-top: 10px; }
	#boxed #wrapper { width: auto !important; margin:0 auto; -webkit-box-shadow: none; box-shadow: none; }

	.inner,
	.pagemid > .inner,
	.videoslider,
	.header-area,
	.page_content,
	.section_inner,
	.iva_mmenu,
	.subheader-inner {  width: 90% !important;  }

	.header-style2 .menu-inner { width: auto !important; }
	.header-style2 .primarymenu { border: none; display: table-cell; margin: 0 0 0 auto; padding: 0; position: relative; vertical-align: middle; }
	.header-style2 .logo { display: table-cell; margin:0 auto 0 0; }
	.header-style3 .iva-mobile-dropdown { margin: 10px 0 10px auto; }
	.header-style5 .iva-mobile-dropdown { margin: 20px 0 0 auto; }
	#fixedheader .iva-mobile-dropdown { margin: 20px 0 0 auto; }
	.nomargin.one_third { width: 33%;}

	.leftsidebar .content-area,
	.rightsidebar .content-area {
		float: none;
		width: auto;
		padding-left:0;
		padding-right:0;
		border: none;
	}
	.nomargin.one_fourth {
    width: 35%;
	}
	.speakers-wrap .one_fourth {
	    width: 25%;
	    margin-right: 0;
	    float: left;
	}
	.at-staff-wapper .at-staff-info .info {
	    display: table-cell;
	    font-size: 13px;
	    vertical-align: top;
	    font-weight: 600;
	    line-height: 24px;
	}

	/* Footer 2 Columns */
	.footer-area .one_fourth { width: 48%; }
	.footer-area .one_fourth:nth-child(even) { margin-right: 0;}

	.rightsidebar #sidebar,
	.leftsidebar #sidebar { float:left; width:100%; }

	.rightsidebar #sidebar .content { padding:0; border:none; }
	.leftsidebar #sidebar .content { padding:0; border:none; }
	.section_bg { background-image: none !important;}
	.header-style4 .search-box { display:none !important; }
	.check-wrap { margin-left: 0; display: inline-block; }

	.partial_section_image { display: block; width: 100%; }
	.partial_section_content { display: block; width: 100%; }

	.header-style4 .iva-mobile-dropdown{ margin: 10px 0; }
	.header-style4 .header-area { width: 100% !important; }
	.header-style4 .iva-mobile-menu{
		position: absolute;
	  	top: 70px;
	  	background-color: #252525;
	  	padding: 0;
	  	z-index: 99999;
	  	width: 100%;
	  	left: 0;
	}
	.iva_instagram .one_fifth {
		  width: 50%;
		  margin: 0 !important;
	}
	.service {
		padding: 50px 5px !important;
	}
	.nomargin.one_fourth { width: 25%;}
	.milestones_area .vc_col-sm-3 { width: 33%; }

	.milestone_content,
	.milestone_icon,
	.copyright_left,
	.copyright_right,
	.copyright_right .textright { float: none; width: 100%; display: block; text-align: center; }

	.iva_blogpost_wrap .blog-item.col_fourth,
	.iva_blogpost_wrap .blog-item.col_third {
		width: 48%;
	}

	.gallery-list.col_fourth { width: 48%; }
	.gallery-list.col_fourth:nth-child(2n),
	.partial-content-wrap { width: 100% !important; }

	/* since - 2.0.0 */
	/* Verticle Menu */
	.sidemenu { position: relative; width: 100%; box-shadow: none; }
	.iva_vertical_menu{ display: none; }
	.vertical_footerbar { display: none; }
	.boxed.vertical_leftmenu { padding: 0; }
	.iva-children-indenter { display: block !important; }
	.sidemenu .logo { padding: 20px 60px 20px 20px; text-align: left; margin: 0; }
	.sidemenu .menuwrap {
	    position: absolute;
	    top: 50%;
	    right: 15px;
	    margin-top: -10px;
	}
	.nomargin.one_half .content-block {
    width: 100%;
	}
	.content-block {
    padding: 10px 60px !important;
	}
}

@media only screen and (min-width: 320px) and (max-width: 466px) {
.three_fifth { padding: 15px 15px !important;}
}

/* iPhone 5s(landscape)
======================================================================== */
@media only screen and (min-width: 480px) and (max-width: 767px) {

	.at-partner.style1 .at-partner-image {
	    float: none;
	    margin: 0 auto 30px 0;
	    max-width: 100%;
	}

	.at-service-container.col_3 .at-item.style1,
	.at-service-container.col_3 .at-item.style2,
	.at-service-container.col_3 .at-item.style3,
	.at-service-container.col_4 .at-item.style1,
	.at-service-container.col_4 .at-item.style2,
	.at-service-container.col_4 .at-item.style3,
	.at-service-container.col_2 .at-item.style1,
	.at-service-container.col_2 .at-item.style2,
	.at-service-container.col_2 .at-item.style3,
	.at-service-container.col_1 .at-item.style1,
	.at-service-container.col_1 .at-item.style2,
	.at-service-container.col_1 .at-item.style3 {
		width: 100%;
	}

	#fixedheader .iva-mobile-dropdown { margin: 20px 0 0 auto; }
	.at-career-table td:nth-child(2),
	.at-career-table th:nth-child(2) { display: none; }

	#iva-mobile-nav-icon{ margin:0; }
	.fixed-header { position:relative; }
	.widget-left-s3 { display: none; }
	.widget-left-s3-mobile { background-color: #353535; color: #ffffff; }
	.widget-left-s3-mobile .widget-left-s3 { width: 90%; float: none; display: table;  }
	.widget-left-s3-mobile .icon-box_widget { vertical-align: middle; display: block;  }

	.callOutBox .callout_btn { float: left !important; margin-left: 10px; margin-top: 10px; }
	#boxed #wrapper { width: auto !important; margin:0 auto; -webkit-box-shadow: none; box-shadow: none; }

	.inner,
	.pagemid > .inner,
	.section_inner,
	.videoslider,
	.header-area,
	.subheader-inner,
	.menu-inner,
	.iva_mmenu,
	.page_content { width: 90% !important; }

	.header-style-default .menuwrap {
	    display: block;
	    position: absolute;
	    top: 50%;
	    right: 10px;
	    margin-top: -12px;
	}
	.header-style2 .primarymenu { border: none; display: table-cell; margin: 0 0 0 auto; padding: 0; position: relative; vertical-align: middle; }
	.header-style2 .logo { display: table-cell; margin:0 auto 0 0; }
	.header-style3 .iva-mobile-dropdown { margin: 0; }

	.rightsidebar #sidebar,
	.leftsidebar #sidebar { float:left; width:100%; }

	.leftsidebar .content-area { float:none; width: auto; padding-left:0; border: none; }
	.rightsidebar .content-area { float:none; width: auto; padding-right:0; border: none; }

	.rightsidebar #sidebar .content { padding:0; border:none;}
	.leftsidebar #sidebar .content { padding:0; border:none;}

	.subdesc,
	.breadcrumbs { float: none !important; display: block !important; }

	.one_half,
	.one_third,
	.two_third,
	.one_fourth,
	.one_fifth,
	.four_fifth,
	.one_sixth,
	.five_sixth,
	.two_fifth,
	.three_fifth,
	.at-person.grid.col_3 ul li,
	.three_fourth {
		width: 100% !important;
		margin-right:0;
	}

	.section_bg { background-image: none !important;}
	.topbar * { text-align:center !important; }

	.copyright_left,
	.copyright_right { width:100%; float:none; text-align:center; }

	.pricetable .column { float: none; width: 100%; border-width:1px; margin-bottom: 20px; }
	.post-metadata { display: none; }
	.iva_one_half, .iva_one_third { width:100%;}
	.iva-testimonial { display:block; border-width:0 0 1px 0; }

	.vertabs .tabs { float: none; width:100%; }
	.vertabs .tabs li { border-width:1px 1px 0; }
	.vertabs .tabs li:last-child { border-width:1px 1px 1px 1px; }

	.iva-np-title { display:none;}
	.iva-np-allitems,
	.iva-np-navs { width: 160px;}

	.header-style4 .search-box { display:none !important; }

	.tc-details { float: none; margin-bottom: 15px; }
	.header-style2 .menu-dropdown { margin-top: 30px;}

	/* New Fixes */
	.fancyheading .xlarge { font-size: 30px !important; }
	.fancyheading .large { font-size: 26px !important; }
	.vertabs .tabs li, .hortabs .tabs li { width: 100%; float: none; }
	blockquote.alignright,
	blockquote.alignleft,
	blockquote.aligncenter { width: 100% !important; margin-right:0 !important; margin-left:0 !important;}

	.blog-item { width: 100%;}
	.content-block {
    padding: 0 60px !important;
	}

	.milestone_icon {
		margin: 0 auto;
    	display: table;
    	float: none;
	}

	.copyright_left .textright,
	.copyright_right .textright { text-align: center; }
	.appt_field label {
    	display: block;
    	margin: 5px 0;
    	padding: 5px;
    	background-color: #F9F9F9;
	}

	.testimonial-carousel { margin: 0 10px; }

	.comments-area .comment-author .avatar { width: 40px; }
	.comments-area .comment-content { padding-left: 0; }

	.sharing-box h4,
	.sharing-box-ico { width:100%; display: table; table-layout: fixed; margin: 10px auto; text-align: center; }

	#back-top { display: none !important; }

	/* Verticle Menu */
	.sidemenu {
		position: relative;
		width: 100%;
		box-shadow: none;
	}
	.iva_vertical_menu{
		display: none;
	}

	.vertical_footerbar {
		display: none;
	}
	body.boxed.vertical_leftmenu{ padding: 0;}

	.iva-children-indenter { display: block !important; }
	.sidemenu .logo { padding: 20px 60px 20px 20px; text-align: left; margin: 0; }
	.sidemenu .menuwrap {
	    position: absolute;
	    top: 50%;
	    right: 15px;
	    margin-top: -10px;
	}
	.one_half .content-area {
    width: 100%;
	}
}

/* Smartphones and iPhone 4s(landscape)
======================================================================== */
@media only screen and (min-width: 320px) and (max-width: 480px) {

	.col_2, .col_3, .col_4, .col_5, .col_6,	.col_7,	.col_8,	.col_9,	.col_10, .col_11, .col_12 { width: 100% !important; float: none !important; }
	.at-cases-main .at-cases-item,
	.at-cases-wrapper.col_3 .at-cases-item { width: 100%; }

	.at-staff-wapper .at-staff-info .info { display: block; }

	.at-partner.style1 .at-partner-image,
	.at-person.list .at-person-image {
	    float: none;
	    margin: 0 auto 30px 0;
	    max-width: 100% !important;
	}
	.at-partner.style1 .at-partner-content,
	.at-person.list .at-person-content  {
      overflow: visible!important;
	}
	.header-style-default .menuwrap {
	    display: block;
	    position: absolute;
	    top: 50%;
	    right: 10px;
	    margin-top: -12px;
	}
	.iva-mobile-menu > ul {
    padding: 0 15px;
	}

	body:not(.fullwidth) .entry-content-wrapper { padding: 80px 15px 40px 15px;}

	.at-service-container.col_3 .at-item.style1,
	.at-service-container.col_3 .at-item.style2,
	.at-service-container.col_3 .at-item.style3,
	.at-service-container.col_4 .at-item.style1,
	.at-service-container.col_4 .at-item.style2,
	.at-service-container.col_4 .at-item.style3,
	.at-service-container.col_2 .at-item.style1,
	.at-service-container.col_2 .at-item.style2,
	.at-service-container.col_2 .at-item.style3,
	.at-service-container.col_1 .at-item.style1,
	.at-service-container.col_1 .at-item.style2,
	.at-service-container.col_1 .at-item.style3 {
		width: 100%;
	}
	#subheader {padding: 0 20px;}
	#subheader .page-title { font-size: 15px;}
	.company-heading h2 {
		font-size: 18px !important;

	}

	.at-career-table td:nth-child(2),
	.at-career-table th:nth-child(2) { display: none; }

	#iva-mobile-nav-icon{ margin:0; }
	.widget-left-s3 { display: none; }
	.widget-left-s3-mobile { background-color: #353535; color: #ffffff; }
	.widget-left-s3-mobile .widget-left-s3 { width: 90%; float: none; display: table;  }
	.widget-left-s3-mobile .icon-box_widget { vertical-align: middle; display: block;  }
	img { width:auto; max-width:100%; height:auto;}
	figure img { width: auto; max-width: 100%; height: auto; }

	.fixed-header { position:fixed !important; }
	.callOutBox .callout_btn { float: left !important; margin-left: 10px; margin-top: 10px; }
	#boxed #wrapper { width: auto !important; margin:0 auto; -webkit-box-shadow: none; box-shadow: none; }

	.leftsidebar .content-area { float:none; width: auto; padding-left:0; border: none; }
	.rightsidebar .content-area { float:none; width: auto; padding-right:0; border: none; }

	.sermon-thumb,
	.iva-location-image { display: block !important; float: none !important; width: auto !important; margin-right:0 !important; }

	.iva-location-indetails,
	.iva-location-details { display: block !important; width: auto !important; }

	.inner,
	.pagemid > .inner,
	.section_inner,
	.videoslider,
	.header-area,
	.subheader-inner,
	.menu-inner,
	.iva_mmenu,
	.page_content {  width: 100%;  }

	.rightsidebar #sidebar,
	.leftsidebar #sidebar { float:left; width:100%; padding: 0 20px;}

	.rightsidebar #sidebar .content {padding:0; border:none; }
	.leftsidebar #sidebar .content {padding:0; border:none; }

	.header-style2 .logo { display: block; }

	.one_half,
	.one_third,
	.two_third,
	.one_fourth,
	.one_fifth,
	.four_fifth,
	.one_sixth,
	.five_sixth,
	.two_fifth,
	.three_fifth,
	.at-person.grid.col_3 ul li,
	.three_fourth { width: 100% !important;  margin-right:0; padding: 0 20px;}


	/* Column Grid  */
	.col_half, .col_third, .col_twothird, .col_three_fourth, .col_fourth, .col_fifth { float: none !important; width:100% !important;  }

	.section_bg { background-image: none !important;}
	.topbar * { text-align:center !important; }
	.topbar-right { width: 75% !important; }

	.copyright_left,
	.copyright_right { width:100%; float:none; text-align:center; }

	.pricetable .column { float: none; width: 100%; border-width:1px; margin-bottom: 20px; }
	.post-metadata { display: none; }

	.subdesc,
	.breadcrumbs { float: none !important; display: block !important; }

	.iva_one_half, .iva_one_third  { width:100%;}
	.iva-testimonial { display:block; border-width:0 0 1px 0; }

	.vertabs .tabs { float: none; width:100%; }
	.vertabs .tabs li { border-width:1px 1px 0; }
	.vertabs .tabs li:last-child { border-width:1px 1px 1px 1px; }

	.iva-np-title { display:none;}
	.iva-np-allitems,
	.iva-np-navs { width: 160px;}

	.header-style4 .search-box { display:none !important; }

	.tc-details { float: none; margin-bottom: 15px; }
	.header-style2 .menu-dropdown { margin-top: 30px;}

	/* New Fixes */
	.fancyheading .xlarge { font-size: 30px !important; }
	.fancyheading .large { font-size: 26px !important; }
	.vertabs .tabs li, .hortabs .tabs li { width: 100%; float: none; }
	blockquote.alignright,
	blockquote.alignleft,
	blockquote.aligncenter { width: 100% !important; margin-right:0 !important; margin-left:0 !important;}

	.iva_instagram .one_fifth {
		  width: 50%;
		  margin: 0 !important;
	}

	.comments-area .comment-author .avatar { width: 40px; }
	.comments-area .comment-content { padding-left: 0; }

	.copyright_left .textright,
	.copyright_right .textright { text-align: center; }
	.appt_field label {
    	display: block;
    	margin: 5px 0;
    	padding: 5px;
    	background-color: #F9F9F9;
	}

	.session-counter-text,
	.session-counter-button,
	.session_list,
	.session_info,
	.session_details { width: 100% !important; display: block !important;  }

	.session-counter-text { margin-bottom: 10px; }
	.testimonial-carousel { margin: 0 10px; }
	.speaker-right { padding-top: 30px;}

	.sharing-box h4,
	.sharing-box-ico { width:100%; display: table; table-layout: fixed; margin: 10px auto; text-align: center; }

	.single-session-meta,
	.single-session-speakers { display: block; text-align: center; width: 100%; border: 0; }

	#back-top { display: none !important; }

	/* Verticle Menu */
	.sidemenu {
		position: relative;
		width: 100%;
		box-shadow: none;
	}
	.iva_vertical_menu{
		display: none;
	}

	.vertical_footerbar {
		display: none;
	}
	body.boxed.vertical_leftmenu{ padding: 0;}

	.iva-children-indenter { display: block !important; }
	.sidemenu .logo { padding: 20px 60px 20px 20px; text-align: left; margin: 0; }
	.sidemenu .menuwrap {
	    position: absolute;
	    top: 50%;
	    right: 15px;
	    margin-top: -10px;
	}

	.at-staff-wapper .at-staff-info .info {
   	 display: block !important;
	}

	.at-callOut_btn,
	.at-callOut_text { display: block; margin: 10px 20px !important;}
	.at-callout-action-text h2 {
		font-size: 18px !important;
	}
	.content-block {
    	padding: 10px 60px !important;
	}
	.footer-area-middle .inner-row {
		margin: 0 !important;
	}
}

/* Small Mobile Devices
======================================================================== */
@media only screen and (min-width: 240px) and (max-width: 319px) {
	.at-partner.style1 .at-partner-image {
	    float: none;
	    margin: 0 auto 30px 0;
	    max-width: 100%;
	}
	.at-career-table td:nth-child(2),
	.at-career-table th:nth-child(2) { display: none; }
	.header-style-default .menuwrap {
	    display: block;
	    position: absolute;
	    top: 50%;
	    right: 10px;
	    margin-top: -12px;
	}

	.at-service-container.col_3 .at-item.style1,
	.at-service-container.col_3 .at-item.style2,
	.at-service-container.col_3 .at-item.style3,
	.at-service-container.col_4 .at-item.style1,
	.at-service-container.col_4 .at-item.style2,
	.at-service-container.col_4 .at-item.style3,
	.at-service-container.col_2 .at-item.style1,
	.at-service-container.col_2 .at-item.style2,
	.at-service-container.col_2 .at-item.style3,
	.at-service-container.col_1 .at-item.style1,
	.at-service-container.col_1 .at-item.style2,
	.at-service-container.col_1 .at-item.style3 {
		width: 100%;
	}

	#iva-mobile-nav-icon{ margin:0; }
	.fixed-header { position:relative; }
	.widget-left-s3 { display: none; }
	.widget-left-s3-mobile { background-color: #353535; color: #ffffff; }
	.widget-left-s3-mobile .widget-left-s3 { width: 90%; float: none; display: table;  }
	.widget-left-s3-mobile .icon-box_widget { vertical-align: middle; display: block;  }
	/* Header */
	.header-area { display: block; }
	.logo { padding: 20px 60px 20px 20px; display: block; }
	.header-style1 .menuwrap { display: block; position: absolute; top: 50%;  right: 10px; margin-top: -12px; }
	.header-style2 .logo { display:block; margin:0 auto; float: none; padding: 20px 60px 20px 20px; width: auto; text-align: inherit; }
	.header-style2 .menuwrap { display: block; position: absolute; top: 50%;  right: 10px; margin-top: -12px; }

	.header-style3 .menuwrap { display: block; position: absolute; top: 50%;  right: 10px; margin-top: -12px; }

	.callOutBox .callout_btn { float: left !important; margin-left: 10px; margin-top: 10px; }
	#boxed #wrapper { width: auto !important; margin:0 auto; -webkit-box-shadow: none; box-shadow: none; }

	.inner,
	.pagemid > .inner,
	.section_inner,
	.videoslider,
	.header .inner,
	.subheader-inner,
	.iva_mmenu,
	.menu-inner,
	.page_content {  width: 90% !important; margin: 0 auto; }

	.leftsidebar .content-area { width: auto; padding-left:0; border: none; }
	.rightsidebar .content-area { width: auto; padding-right:0; border: none; }

	.iva-location-image { float: none !important; width: auto !important; margin-right:0 !important; }

	.iva-location-indetails,
	.iva-location-details { width: auto !important; }

	.rightsidebar #sidebar,
	.leftsidebar #sidebar { float:left; width:100%; }

	.rightsidebar #sidebar .content { padding:0; }
	.leftsidebar #sidebar .content { padding:0; }

	.inner,
	.pagemid > .inner,
	.section_inner,
	.videoslider,
	.header .inner,
	.subheader-inner,
	.iva_bk_inner ,
	.leftsidebar .content-area,
	.rightsidebar .content-area { width: 200px; margin:0 auto;  }

	.one_half,
	.one_third,
	.two_third,
	.one_fourth,
	.one_fifth,
	.four_fifth,
	.one_sixth,
	.five_sixth,
	.two_fifth,
	.three_fifth,
	.at-person.grid.col_3 ul li,
	.three_fourth { width: 200px;  margin:0 auto; float:none;}

	.section_bg { background-image: none !important;}

	.topbar * { text-align:center !important; }
	.topbar-right { width: 75% !important; }

	.copyright_left,
	.copyright_right { width:100%; float:none; text-align:center; }

	.pricetable .column { float: none; width: 100%; border-width:1px; margin-bottom: 20px; }

	.post-metadata { display: none; }

	.subdesc,
	.breadcrumbs { float: none !important; display: block !important; }

	.iva_one_half, .iva_one_third { width:100%;}
	.iva-testimonial { display:block; border-width:0 0 1px 0; }

	.vertabs .tabs { float: none; width:100%; }
	.vertabs .tabs li { border-width:1px 1px 0; }
	.vertabs .tabs li:last-child { border-width:1px 1px 1px 1px; }

	.iva-np-title { display:none;}
	.iva-np-allitems,
	.iva-np-navs { width: 160px;}

	.header-style4 .search-box { display:none !important; }

	.tc-details { float: none; margin-bottom: 15px; }
	.header-style2 .menu-dropdown { margin-top: 30px;}

	/* New Fixes */
	.fancyheading .xlarge { font-size: 30px !important; }
	.fancyheading .large { font-size: 26px !important; }
	.vertabs .tabs li, .hortabs .tabs li { width: 100%; float: none; }
	blockquote.alignright,
	blockquote.alignleft,
	blockquote.aligncenter { width: 100% !important; margin-right:0 !important; margin-left:0 !important;}

	.speaker-left {
	    width: 100%;
	    float: none;
	    display: block;
	    margin-right: 0;
	}
	.iva_instagram .one_fifth {
		  width: 50%;
		  margin: 0 !important;
	}

	.schedule-tabs li {
    	width: 100%;
	}

	.schedule-subtabs li {
		width: 100%;
	}

	.schedule-subtab-list .active {
		right: 0;
    	left: 90%;
    	bottom: 15px;
	}

	.milestone_icon {
		float: none;
    	display: table;
    	margin: 0 auto;
	}

	.session-counter-text { margin-bottom: 10px; }
	.testimonial-carousel { margin: 0 10px; }

	.sharing-box h4,
	.sharing-box-ico { width:100%; display: table; table-layout: fixed; margin: 10px auto; text-align: center; }

	#back-top { display: none !important; }

	.comments-area .comment-author .avatar { width: 40px; }
	.comments-area .comment-content { padding-left: 0; }

	/* Verticle Menu */
	.sidemenu {
		position: relative;
		width: 100%;
		box-shadow: none;
	}
	.iva_vertical_menu{
		display: none;
	}

	.vertical_footerbar {
		display: none;
	}
	body.boxed.vertical_leftmenu{ padding: 0;}

	.iva-children-indenter { display: block !important; }
	.sidemenu .logo { padding: 20px 60px 20px 20px; text-align: left; margin: 0; }
	.sidemenu .menuwrap {
	    position: absolute;
	    top: 50%;
	    right: 15px;
	    margin-top: -10px;
	}
	.at-staff-wapper .at-staff-info .info {
   	 display: block !important;
	}
}

/* Responsive Header
======================================================================== */
@media only screen and (max-width : 767px ) {
	.at-career-table td:nth-child(2),
	.at-career-table th:nth-child(2) { display: none; }

	.at-service-container.col_3 .at-item.style1,
	.at-service-container.col_3 .at-item.style2,
	.at-service-container.col_3 .at-item.style3,
	.at-service-container.col_4 .at-item.style1,
	.at-service-container.col_4 .at-item.style2,
	.at-service-container.col_4 .at-item.style3,
	.at-service-container.col_2 .at-item.style1,
	.at-service-container.col_2 .at-item.style2,
	.at-service-container.col_2 .at-item.style3,
	.at-service-container.col_1 .at-item.style1,
	.at-service-container.col_1 .at-item.style2,
	.at-service-container.col_1 .at-item.style3 {
		width: 100%;
	}

	#iva-mobile-nav-icon{ margin:0; }
	img { width:auto; max-width:100%; height:auto;}
	figure img { width: auto; max-width: 100%; height: auto; }
	.widget-left-s3 { display: none; }
	.widget-left-s3-mobile { background-color: #353535; color: #ffffff; }
	.widget-left-s3-mobile .widget-left-s3 { width: 90%; float: none; display: table;  }
	.widget-left-s3-mobile .icon-box_widget { vertical-align: middle; display: block;  }
	.fixed-header { position:relative; }

	/* Header */
	.header-area { display: block; }
	.logo { padding: 20px 60px 20px 20px; display: block; }

	.header-style1 .menuwrap {
		display: block;
		position: absolute;
		top: 50%;
		right: 15px;
		margin-top: -10px;
	}
	.header-style2 .logo {
		display:block;
		margin:0 auto;
		float: none;
		padding: 20px 60px 20px 20px;
		width: auto;
		text-align: inherit;
	}
	.header-style2 .menuwrap {
		display: block;
		position: absolute;
		top: 50%;
		right: 15px;
		margin-top: -10px;
	}

	.header-style3 .menuwrap {
		display: block;
		position: absolute;
		top: 50%;
		right: 15px;
		margin-top: -10px;
	}
	.header-style3 .primarymenu { border: none; }
	#fixedheader .menuwrap {
		display: block;
		position: absolute;
		top: 50%;
		right: 15px;
		margin-top: -10px;
	}

	.nomargin.one_half,	.nomargin.one_third, .nomargin.two_third,
	.nomargin.one_fourth, .nomargin.one_fifth, .nomargin.four_fifth,
	.nomargin.one_sixth, .nomargin.five_sixth, .nomargin.two_fifth,
	.nomargin.three_fifth, .nomargin.three_fourth {
		margin-bottom: 20px;
		float: none;
		width: 100%;
	}

	.partial_section_image { display: block; width: 100%; }
	.partial_section_content { display: block; width: 100%; }
	.iva-bookings { margin: 0 0 10px 0 !important; }

	.header-s3-right { display: none; }
	.logo img { transform:scale(1); }
	.icn_wrap { display: none; }

	/* Header Style 4 */
	.services-box { margin-top: 0 !important; }
	.header-area { width:100% !important; display: block; height: auto; margin: 0 auto; }
	.header-style4 .logo {
		  text-align: left;
		  padding: 20px 60px 20px 20px;
		  margin: 0;
		  max-width: none;
		  display: block;
	}
	.topbar * { text-align:center !important; }
	.topbar-left { float: none; width: 100%; padding: 0; }
	.topbar-right { float: none; padding: 0; }
	.topbar-right .atpsocials { float: none; margin: 5px 0; }
	.icn_wrap_align { display: none; }
	.header-style4 .menuwrap { float: none; }
	.header-style4 .iva-mobile-dropdown { margin: 15px auto; }
	.offers-content { padding: 30px 0; }
	.special_offers_item { padding: 0; }

	.sessiontab-title-details {
	    display: block;
	    float: none;
	    text-align: center;
		margin-bottom: 20px;
	}
	.sessiontab-button {
	    overflow: hidden;
	    float: none;
	    margin: 0 auto;
	    display: table;
	}
	.iva_instagram .one_fifth {
		  width: 50%;
		  margin: 0 !important;
	}
	.milestone_icon {
		float: none;
    	display: table;
    	margin: 0 auto;
	}
	.footer-area-middle {
    	padding: 60px 0 40px 20px;
	}

	#back-top { display: none !important; }

	article.post:not(.singlepost) .postimg {
		display:block;
		width: 100%;
		margin-bottom: 20px;
		float: none;
	}
	.comments-area .comment-author .avatar { width: 40px; }
	.comments-area .comment-content { padding-left: 0; }
	.comment-list .children { margin-left: 10px; }
	#sticky, .tarrow { display: none; }

	/* Verticle Menu */
	.sidemenu { position: relative; width: 100%; box-shadow: none; }
	.iva_vertical_menu { display: none; }
	.vertical_footerbar { display: none; }
	.boxed.vertical_leftmenu { padding: 0; }
	.iva-children-indenter { display: block !important; }
	.sidemenu .logo { padding: 20px 60px 20px 20px; text-align: left; margin: 0; }
	.sidemenu .menuwrap {
	    position: absolute;
	    top: 50%;
	    right: 15px;
	    margin-top: -10px;
	}
}



